import React from 'react';
import {makeStyles, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    pre: {
        whiteSpace: 'pre-wrap',
    },
}));

const PaperWithHeadline = (props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Typography variant="h6">{props.headline}</Typography>
            <pre className={classes.pre}>
                {props.children}
            </pre>
        </Paper>
    );
};

export default PaperWithHeadline;