import React, {Fragment, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        paddingLeft: '8px',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
}));

const ThemeList = (props) => {

    const classes = useStyles();

    const [themesToggled, setThemesToggled] = useState(true);

    if (!props.result.hits) {
        return null;
    }

    const items = [];
    for (const hit of props.result.hits) {
        const item = {
            id: hit.themaId,
            score: hit._score,
            name: hit.titel,
            subline: hit.untertitel,
            seminarCount: hit.seminarCount,
            link: hit.seminarlink,
            inPerson: hit.inPerson,
            online: hit.online,
            type: hit.type,
        };

        items.push(item);
    }

    let locationFilter = null;
    if (props.result.aggregations && props.result.aggregations.location) {
        locationFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Locations</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.location.map((location) => {
                        let color = 'default';
                        if (props.selectedLocations.indexOf(location.id) !== -1) {
                            color = 'primary';
                        }
                        if (location.count === 0) {
                            color = 'secondary';
                        }
                        const label = location.name + ' ('+ location.count +')';
                        return (<Chip key={location.id} label={label} color={color} onClick={() => {props.toggleLocation(location.id)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    let themeFilter = null;
    if (props.result.aggregations.theme) {
        let countThemes = 0;
        themeFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Themes</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.theme.map((theme) => {
                        countThemes++;
                        if (countThemes === 20 && themesToggled) {
                            return (<div onClick={() => {setThemesToggled(false);}}>[...]</div>);
                        }
                        if (countThemes > 20 && themesToggled) {
                            return null;
                        }
                        let color = 'default';
                        if (props.selectedThemes.indexOf(theme.id) !== -1) {
                            color = 'primary';
                        }
                        if (theme.count === 0) {
                            color = 'secondary';
                        }
                        const label = theme.name + ' ('+ theme.count +')';
                        return (<Chip key={theme.id} label={label} color={color} onClick={() => {props.toggleTheme(theme.id)}} />);
                    })}
                    <div onClick={() => {setThemesToggled(!themesToggled);}}>Toggle themes</div>
                </div>
            </React.Fragment>
        );
    }

    let weekFilter = null;
    if (props.result.aggregations.week) {
        weekFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Weeks</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.week.map((week) => {
                        let color = 'default';
                        if (props.selectedWeeks.indexOf(week.id) !== -1) {
                            color = 'primary';
                        }
                        if (week.count === 0) {
                            color = 'secondary';
                        }
                        const label = week.name + ' ('+ week.count +')';
                        return (<Chip key={week.id} label={label} color={color} onClick={() => {props.toggleWeek(week.id)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    let monthFilter = null;
    if (props.result.aggregations.month) {
        monthFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Months</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.month.map((month) => {
                        let color = 'default';
                        if (props.selectedMonths.indexOf(month.id) !== -1) {
                            color = 'primary';
                        }
                        if (month.count === 0) {
                            color = 'secondary';
                        }
                        const label = month.name + ' ('+ month.count +')';
                        return (<Chip key={month.id} label={label} color={color} onClick={() => {props.toggleMonth(month.id)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    let typesFilter = null;
    if (props.result.aggregations.types) {
        typesFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Types</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.types.map((type) => {
                        let color = 'default';
                        if (props.selectedTypes.indexOf(type.id) !== -1) {
                            color = 'primary';
                        }
                        if (type.count === 0) {
                            color = 'secondary';
                        }
                        const label = type.name + ' ('+ type.count +')';
                        return (<Chip key={type.id} label={label} color={color} onClick={() => {props.toggleType(type.id)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    const hotelFilter = [];
    const hotel = ['barrierefrei', 'rollstuhlgerecht', 'ice_anbindung', 'allergiker_zimmer', 'freizeit_fitness', 'ladesaeule',];
    for (const boolName of hotel) {
        if (props.result.aggregations[boolName]) {
            for (const bucket of props.result.aggregations[boolName]) {
                if (bucket.id === 1) {
                    hotelFilter.push({
                        name: boolName,
                        count: bucket.count
                    });
                    break;
                }
            }
        }
    }

    const weiterbildungFilter = [];
    const weiterbildung = ['inPerson', 'typeWebinar', 'typeInhouse', 'typeFachtagung', 'typeSymposium', 'typeAusbildung', 'typeFreeWebinar', 'typeDaySeminar'];
    for (const boolName of weiterbildung) {
        if (props.result.aggregations[boolName]) {
            for (const bucket of props.result.aggregations[boolName]) {
                if (bucket.id === 1) {
                    weiterbildungFilter.push({
                        name: boolName,
                        count: bucket.count
                    });
                    break;
                }
            }
        }
    }

    const otherFilter = [];
    const other = ['starterPaket', 'rhetorik', 'arbeitsgericht', 'sozialgericht'];
    for (const boolName of other) {
        if (props.result.aggregations[boolName]) {
            for (const bucket of props.result.aggregations[boolName]) {
                if (bucket.id === 1) {
                    otherFilter.push({
                        name: boolName,
                        count: bucket.count
                    });
                    break;
                }
            }
        }
    }

    const boolsFilter = [];
    const bools = ['ausgebuchtAusblenden', 'preisVorteil', 'durchfuehrungsgarantie'];
    for (const boolName of bools) {
        if (props.result.aggregations[boolName]) {
            for (const bucket of props.result.aggregations[boolName]) {
                if (bucket.id === 1) {
                    boolsFilter.push({
                        name: boolName,
                        count: bucket.count
                    });
                    break;
                }
            }
        }
    }

    let categoryFilter = null;
    if (props.result.aggregations.category) {
        categoryFilter = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Category</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.category.map((category) => {
                        let color = 'default';
                        if (props.selectedCategories.indexOf(category.id) !== -1) {
                            color = 'primary';
                        }
                        if (category.count === 0) {
                            color = 'secondary';
                        }
                        const label = category.name + ' ('+ category.count +')';
                        return (<Chip key={category.id} label={label} color={color} onClick={() => {props.toggleCategory(category.id)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    return (
        <TableContainer component={Paper} style={{marginBottom: '20px'}}>
            {boolsFilter.map((boolFilter) => {
                let color = 'default';
                if (props.selectedBools.indexOf(boolFilter.name) !== -1) {
                    color = 'primary';
                }
                if (boolFilter.count === 0) {
                    color = 'secondary';
                }

                const label = boolFilter.name + ' ('+ boolFilter.count +')';
                return (
                    <Fragment>
                        <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>{boolFilter.name}</Typography>
                        <div className={classes.chipRoot}>
                            <Chip key={boolFilter.name} label={label} color={color} onClick={() => {props.toggleBool(boolFilter.name)}} />
                        </div>
                    </Fragment>
                );
            })}
            <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Hoteloptionen</Typography>
            <div className={classes.chipRoot}>
                {hotelFilter.map((boolFilter) => {
                    let color = 'default';
                    if (props.selectedBools.indexOf(boolFilter.name) !== -1) {
                        color = 'primary';
                    }
                    if (boolFilter.count === 0) {
                        color = 'secondary';
                    }
                    const label = boolFilter.name + ' ('+ boolFilter.count +')';
                    return (<Chip key={boolFilter.name} label={label} color={color} onClick={() => {props.toggleBool(boolFilter.name)}} />);
                })}
            </div>
            <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Weiterbildung</Typography>
            <div className={classes.chipRoot} style={{marginBottom: '5px'}}>
                {weiterbildungFilter.map((boolFilter) => {
                    let color = 'default';
                    if (props.selectedBools.indexOf(boolFilter.name) !== -1) {
                        color = 'primary';
                    }
                    if (boolFilter.count === 0) {
                        color = 'secondary';
                    }
                    const label = boolFilter.name + ' ('+ boolFilter.count +')';
                    return (<Chip key={boolFilter.name} label={label} color={color} onClick={() => {props.toggleBool(boolFilter.name)}} />);
                })}
            </div>
            <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Weitere Optionen</Typography>
            <div className={classes.chipRoot}>
                {otherFilter.map((boolFilter) => {
                    let color = 'default';
                    if (props.selectedBools.indexOf(boolFilter.name) !== -1) {
                        color = 'primary';
                    }
                    if (boolFilter.count === 0) {
                        color = 'secondary';
                    }
                    const label = boolFilter.name + ' ('+ boolFilter.count +')';
                    return (<Chip key={boolFilter.name} label={label} color={color} onClick={() => {props.toggleBool(boolFilter.name)}} />);
                })}
            </div>
            <Typography variant="h6" style={{paddingLeft: '15px'}}>{props.result.count} Themes, {props.result.seminarCount} Seminars</Typography>
            {locationFilter}
            {categoryFilter}
            {themeFilter}
            {weekFilter}
            {monthFilter}
            {typesFilter}
            <Table>
                <TableBody>
                    {items.map(item => (
                        <React.Fragment key={item.id}>
                            <TableRow>
                                <TableCell scope="row">
                                    {item.id}
                                </TableCell>
                                <TableCell><strong>{item.name}</strong> ({item.type}) (Score: {item.score}, Seminars: {item.seminarCount}, in Person: {item.inPerson}, online: {item.online}) - <a href={item.link}>Link</a></TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ThemeList;
