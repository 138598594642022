const defaultState = {
    endpointUrl: localStorage.getItem('endpointUrl') ? localStorage.getItem('endpointUrl') : 'https://elastic.ifb.de.stage.esono.net',
    indexNameSeminars: localStorage.getItem('indexNameSeminarsNew') ? localStorage.getItem('indexNameSeminarsNew') : 'ifb-themen',
    indexNameContent: localStorage.getItem('indexNameContent') ? localStorage.getItem('indexNameContent') : 'ifb-content',
    indexNameKeywords: localStorage.getItem('indexNameKeywords') ? localStorage.getItem('indexNameKeywords') : 'ifb-keywords',
};

const elasticReducer = (previousState = defaultState, action) => {
    switch(action.type) {
        case 'applyElasticEndpoint':
            localStorage.setItem('endpointUrl', action.payload);
            return {...previousState, endpointUrl: action.payload};
        case 'applyIndexNameSeminars':
            localStorage.setItem('indexNameSeminars', action.payload);
            return {...previousState, indexNameSeminars: action.payload};
        case 'applyIndexNameContent':
            localStorage.setItem('indexNameContent', action.payload);
            return {...previousState, indexNameContent: action.payload};
        case 'applyIndexNameKeywords':
            localStorage.setItem('indexNameKeywords', action.payload);
            return {...previousState, indexNameKeywords: action.payload};
        default:
            return previousState;
    }
};

export default elasticReducer;
