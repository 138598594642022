import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import DisplayApiQuery from "./DisplayApiQuery";
import {connect} from "react-redux";
import ThemeList from "../components/ThemeList";
import TextField from "@material-ui/core/TextField";
import {Chip, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
}));

const ThemesGetAll = (props) => {

    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [webcode, setWebcode] = useState('');
    const [seminarNumber, setSeminarNumber] = useState('');
    const [includeEvents, setIncludeEvents] = useState(false);

    const [selectedThemes, setSelectedThemes] = useState([]);
    const toggleTheme = (id) => {
        setSelectedThemes((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(id);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(id);
            }

            return newState;
        });
    };

    const [selectedLocations, setSelectedLocations] = useState([]);
    const toggleLocation = (id) => {
        setSelectedLocations((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(id);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(id);
            }

            return newState;
        });
    };

    const [selectedWeeks, setSelectedWeeks] = useState([]);
    const toggleWeek = (week) => {
        setSelectedWeeks((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(week);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(week);
            }

            return newState;
        });
    };

    const [selectedMonths, setSelectedMonths] = useState([]);
    const toggleMonth = (month) => {
        setSelectedMonths((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(month);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(month);
            }

            return newState;
        });
    };

    const [selectedTypes, setSelectedTypes] = useState([]);
    const toggleType = (type) => {
        setSelectedTypes((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(type);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(type);
            }

            return newState;
        });
    };

    const [selectedCategories, setSelectedCategories] = useState([]);
    const toggleCategory = (category) => {
        setSelectedCategories((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(category);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(category);
            }

            return newState;
        });
    };

    const [selectedBools, setSelectedBools] = useState([]);
    const toggleBool = (name) => {
        setSelectedBools((previousState) => {
            const newState = [...previousState];
            const index = newState.indexOf(name);
            if (index > -1) {
                newState.splice(index, 1);
            } else {
                newState.push(name);
            }

            return newState;
        });
    };

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const searchBody = {
        "from": from,
        "size": size,
        "filters": {},
        position: 'IFB Demo ESONO'
    };

    if (searchTerm !== '' && webcode === '' && seminarNumber === '') {
        searchBody.q = searchTerm;
    }

    if (seminarNumber !== '' && webcode === '') {
        searchBody.seminarNumber = seminarNumber;
    }

    if (webcode !== '') {
        searchBody.themeId = webcode;
    }

    if (selectedLocations.length > 0) {
        searchBody.filters.location = selectedLocations;
    }

    if (selectedThemes.length > 0) {
        searchBody.filters.theme = selectedThemes;
    }

    if (selectedWeeks.length > 0) {
        searchBody.filters.week = selectedWeeks;
    }

    if (selectedMonths.length > 0) {
        searchBody.filters.month = selectedMonths;
    }

    for (const boolName of selectedBools) {
        searchBody.filters[boolName] = true;
    }

    if (selectedTypes.length > 0) {
        searchBody.filters.types = selectedTypes;
    }

    if (selectedCategories.length > 0) {
        searchBody.filters.category = selectedCategories;
    }

    if (startDate !== '') {
        searchBody.filters.startDate = startDate;
    }

    if (endDate !== '') {
        searchBody.filters.endDate = endDate;
    }

    if (Object.keys(searchBody.filters).length === 0) {
        searchBody.isNew = 1;
    }

    const queryUrlSeminars = '/themes';

    const classes = useStyles();

    const componentProps = {
        selectedLocations: selectedLocations,
        toggleLocation: toggleLocation,
        selectedThemes: selectedThemes,
        toggleTheme: toggleTheme,
        selectedWeeks: selectedWeeks,
        toggleWeek: toggleWeek,
        selectedMonths: selectedMonths,
        toggleMonth: toggleMonth,
        selectedTypes: selectedTypes,
        toggleType: toggleType,
        selectedCategories: selectedCategories,
        toggleCategory: toggleCategory,
        selectedBools: selectedBools,
        toggleBool: toggleBool,
    };

    return (
        <React.Fragment>
            <Typography variant="h1">Theme search</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Search term" value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value);
                }} />
                <TextField label="Theme Id" value={webcode} onChange={(e) => {
                    setWebcode(e.target.value);
                }} />
                <TextField label="Seminar number" value={seminarNumber} onChange={(e) => {
                    setSeminarNumber(e.target.value);
                }} />
                <TextField label="Size" value={size} onChange={(e) => {
                    setSize(e.target.value);
                }} />
                <TextField label="From" value={from} onChange={(e) => {
                    setFrom(e.target.value);
                }} />
            </div>
            <Typography variant="h6" style={{fontSize: '1rem'}}>Dates (Format: 2020-01-01T00:00:00.000Z)</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Start date" value={startDate} onChange={(e) => {
                    setStartDate(e.target.value);
                }} />
                <TextField label="End date" value={endDate} onChange={(e) => {
                    setEndDate(e.target.value);
                }} />
                <Chip label="Include events (seminars)" color={includeEvents ? 'primary' : 'default'} onClick={() => {setIncludeEvents(!includeEvents)}}/>
            </div>
            <DisplayApiQuery queryUrl={queryUrlSeminars} queryBody={searchBody} component={ThemeList} componentProps={componentProps}/>
        </React.Fragment>
    );


};

const stateToProps = (state) => {
    return {
        indexNameSeminars: state.elastic.indexNameSeminars,
        indexNameContent: state.elastic.indexNameContent,
        indexNameKeywords: state.elastic.indexNameKeywords,
    }
};

export default connect(stateToProps)(ThemesGetAll);
