import React from 'react';
import {createStore, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AppDrawer from './components/AppDrawer';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from "./containers/Home";
import ContentSearchPage from "./containers/ContentSearchPage";
import elasticReducer from './store/elasticReducer';
import ThemesGetAll from "./containers/ThemesGetAll";
import Seminars from "./containers/Seminars";
import SuggestSearch from "./containers/SuggestSearch";

const theme = createMuiTheme({
  palette: {
    primary: { main: '#bf1d2e' },
    secondary: { main: '#5bacb0' },
  },
});

const rootReducer = combineReducers(
    {
      'elastic': elasticReducer,
    }
);

const store = createStore(rootReducer);

function App() {
  return (
      <Provider store={store}>
        <div className="App">
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppDrawer>
                <Switch>
                  <Route path='/' component={Home} exact/>
                  <Route path='/themeSearch' component={ThemesGetAll} exact/>
                  <Route path='/seminarSearch' component={Seminars} exact/>
                  <Route path='/inhaltssuche' component={ContentSearchPage} exact/>
                  <Route path='/suggestions' component={SuggestSearch} exact/>
                </Switch>
              </AppDrawer>
            </ThemeProvider>
          </Router>

        </div>
      </Provider>
  );
}

export default App;
