import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, Slider, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        paddingLeft: '8px',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
}));

const ContentList = (props) => {
    const rechtsprechungDefaults = {};
    const rechtsprechungLimits = {};
    if (props.result && props.result.aggregations && props.result.aggregations.domains) {
        Object.entries(props.result.aggregations.domains).forEach((domainAggData) => {
            const [domain, domainAggs] = domainAggData;
            if (domainAggs.rechtsprechungDate) {
                rechtsprechungDefaults[domain] = {from: props.filter[domain]?.rechtsprechungDate?.from ?? '', to: props.filter[domain]?.rechtsprechungDate?.to ?? ''};
                rechtsprechungLimits[domain] = {from: domainAggs.rechtsprechungDate.min ?? '', to: domainAggs.rechtsprechungDate.max ?? ''};
            }
        });
    }
    const [rechtsprechungFilter, setRechtsprechungFilter] = useState(rechtsprechungDefaults);
    const classes = useStyles();

    if (!props.result.hits) {
        return null;
    }

    const items = [];
    for (const hit of props.result.hits) {
        const item = {
            title: hit.teaser_title,
            text: hit.teaser_text,
            url: hit.teaser_url,
            key: hit.id,
            id: hit.id,
            score: hit.score,
            type: hit.type,
            date: hit.date ?? ''
        };

        items.push(item);
    }

    const aggregationSelection = (
        <React.Fragment>
            <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1rem'}}>Available aggregations (check nothing to get all)</Typography>
            <div className={classes.chipRoot}>
                {['availableDomains', 'types', 'mediaTags', 'mediaCategories', 'forumCategories', 'mediaPrice', 'postTime', 'rechtsprechungDate'].map((availableAggregation) => {
                    let color = 'default';
                    const isSelected = null !== props.usedAggregations && props.usedAggregations.indexOf(availableAggregation) !== -1;
                    if (isSelected) {
                        color = 'primary';
                    }
                    return (<Chip key={availableAggregation} label={availableAggregation} color={color} onClick={() => {props.toggleAggregation(availableAggregation)}} />);
                })}
            </div>
        </React.Fragment>
    );

    let domainSelection = null;
    if (props.result.aggregations.availableDomains) {
        const selectedDomains = [];
        if (props.filter) {
            selectedDomains.push(...Object.keys(props.filter));
        }

        domainSelection = (
            <React.Fragment>
                <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '1.5rem'}}>Available Domains</Typography>
                <div className={classes.chipRoot}>
                    {props.result.aggregations.availableDomains.map((availableDomain) => {
                        let color = 'default';
                        const isSelected = selectedDomains.indexOf(availableDomain.id) !== -1;
                        if (isSelected) {
                            color = 'primary';
                        }
                        const label = availableDomain.name + ' ('+ availableDomain.count +')';
                        return (<Chip key={availableDomain.id} label={label} color={color} onClick={() => {props.toggleFilterValue(availableDomain.id, isSelected ? null : undefined)}} />);
                    })}
                </div>
            </React.Fragment>
        );
    }

    let domainFilters = [];
    if (props.result.aggregations.domains) {
        Object.keys(props.result.aggregations.domains).forEach((domain) => {
            const mediaFilters = [];
            ['types', 'forumCategories', 'mediaCategories', 'mediaTags'].forEach((mediaFilterName) => {
                if (props.result.aggregations.domains[domain][mediaFilterName]) {
                    mediaFilters.push((
                        <React.Fragment>
                            <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '0.8rem'}}>{mediaFilterName}</Typography>
                            <div className={classes.chipRoot}>
                                {props.result.aggregations.domains[domain][mediaFilterName].map((value) => {
                                    let color = 'default';
                                    if (props.filter[domain] && props.filter[domain][mediaFilterName] && props.filter[domain][mediaFilterName].indexOf(value.id) !== -1) {
                                        color = 'primary';
                                    }
                                    const label = value.name + ' ('+ value.count +')';
                                    return (<Chip key={value.id} label={label} color={color} onClick={() => {props.toggleFilterValue(domain, mediaFilterName, value.id)}} />);
                                })}
                            </div>
                        </React.Fragment>
                    ));
                }
            });

            if (props.result.aggregations.domains[domain].mediaPrice && props.result.aggregations.domains[domain].mediaPrice.min && props.result.aggregations.domains[domain].mediaPrice.max) {
                mediaFilters.push((
                    <React.Fragment>
                        <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '0.8rem'}}>mediaPrice</Typography>
                        <div style={{width: '400px', marginLeft: '20px'}}>
                            <Slider
                                defaultValue={[(props.filter[domain] && props.filter[domain].mediaPrice && props.filter[domain].mediaPrice.from) ?? props.result.aggregations.domains[domain].mediaPrice.min, (props.filter[domain] && props.filter[domain].mediaPrice && props.filter[domain].mediaPrice.to) ?? props.result.aggregations.domains[domain].mediaPrice.max]}
                                onChangeCommitted={(event, newValue) => {
                                    props.setMediaPrice(domain, newValue[0] === props.result.aggregations.domains[domain].mediaPrice.min ? undefined : newValue[0], newValue[1] === props.result.aggregations.domains[domain].mediaPrice.max ? undefined : newValue[1]);
                                }}
                                min={props.result.aggregations.domains[domain].mediaPrice.min}
                                max={props.result.aggregations.domains[domain].mediaPrice.max}
                                valueLabelDisplay="auto"
                                aria-labelledby="price-slider"
                            />
                        </div>
                    </React.Fragment>
                ));
            }

            if (props.result.aggregations.domains[domain].postTime && props.result.aggregations.domains[domain].postTime.min && props.result.aggregations.domains[domain].postTime.max) {
                mediaFilters.push((
                    <React.Fragment>
                        <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '0.8rem'}}>postTime</Typography>
                        <div style={{width: '400px', marginLeft: '20px'}}>
                            <Slider
                                defaultValue={[(props.filter[domain] && props.filter[domain].postTime && props.filter[domain].postTime.from) ?? props.result.aggregations.domains[domain].postTime.min, (props.filter[domain] && props.filter[domain].postTime && props.filter[domain].postTime.to) ?? props.result.aggregations.domains[domain].postTime.max]}
                                onChangeCommitted={(event, newValue) => {
                                    props.setPostTime(domain, newValue[0] === props.result.aggregations.domains[domain].postTime.min ? undefined : newValue[0], newValue[1] === props.result.aggregations.domains[domain].postTime.max ? undefined : newValue[1]);
                                }}
                                min={props.result.aggregations.domains[domain].postTime.min}
                                max={props.result.aggregations.domains[domain].postTime.max}
                                valueLabelDisplay="auto"
                                aria-labelledby="timestamp-slider"
                            />
                        </div>
                    </React.Fragment>
                ));
            }

            if (rechtsprechungFilter[domain]) {
                mediaFilters.push((
                    <React.Fragment>
                        <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '0.8rem'}}>rechtsprechungDate (Min {rechtsprechungLimits[domain].from} / Max {rechtsprechungLimits[domain].to})</Typography>
                        <TextField label="From (YYYY-mm-dd)" value={rechtsprechungFilter[domain].from} onChange={(e) => {
                            const newState = {...rechtsprechungFilter}
                            newState[domain] = {...rechtsprechungFilter[domain], from: e.target.value};
                            setRechtsprechungFilter(newState);
                        }} />
                        <TextField label="To (YYYY-mm-dd)" value={rechtsprechungFilter[domain].to} onChange={(e) => {
                            const newState = {...rechtsprechungFilter}
                            newState[domain] = {...rechtsprechungFilter[domain], to: e.target.value};
                            setRechtsprechungFilter(newState);
                        }} />
                        <Button onClick={() => {props.setRechtsprechungDate(domain, rechtsprechungFilter[domain].from, rechtsprechungFilter[domain].to);}}>Set</Button>
                    </React.Fragment>
                ));
            }

            domainFilters.push((
                <React.Fragment>
                    <Typography variant="h6" style={{paddingLeft: '15px', fontSize: '2rem'}}>{domain}</Typography>
                    {mediaFilters.map((mediaFilter) => {
                        return mediaFilter;
                    })}
                </React.Fragment>
            ));


        });
    }

    return (
        <TableContainer component={Paper} style={{marginBottom: '20px'}}>
            {aggregationSelection}
            {domainSelection}
            {domainFilters.map((domainFilter) => {
                return domainFilter;
            })}
            <Typography variant="h6" style={{paddingLeft: '15px'}}>{props.result.count} Entries</Typography>
            <Table>
                <TableBody>
                    {items.map(item => (
                        <React.Fragment key={item.id}>
                            <TableRow>
                                <TableCell scope="row">
                                    {item.type}
                                </TableCell>
                                <TableCell>{item.date}<br />{item.score}<br /><a href={item.url}>Link</a><br /><h2>{item.title}</h2>{item.text}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ContentList;
