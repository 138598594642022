import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, Typography} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        paddingLeft: '8px',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
}));

const SeminarList = (props) => {

    const classes = useStyles();

    if (!props.result.hits) {
        return null;
    }

    const items = [];
    for (const hit of props.result.hits) {
        const item = {
            id: hit.seminarId,
            themaId: hit.themaId,
            seminarnummer: hit.seminarnummer,
            terminlink: hit.terminlink,
            ort: hit.ort,
            status: `${hit.status} (${hit.buchungsstatus})`,
        };

        items.push(item);
    }

    return (
        <TableContainer component={Paper} style={{marginBottom: '20px'}}>
            <Typography variant="h6" style={{paddingLeft: '15px'}}>{props.result.seminarCount} Seminars</Typography>
            <Table>
                <TableBody>
                    {items.map(item => (
                        <React.Fragment key={item.id}>
                            <TableRow>
                                <TableCell scope="row">
                                    {item.id}
                                </TableCell>
                                <TableCell><strong>{item.seminarNumber}</strong> {item.ort}, <a href={item.terminlink}>Link</a>, Status: {item.status.toString()}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SeminarList;
