import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from "@material-ui/core";

const SuggestTermsList = (props) => {
    if (!props.result) {
        return null;
    }

    return (
        <TableContainer component={Paper} style={{marginBottom: '20px'}}>
            <Typography variant="h6" style={{paddingLeft: '15px'}}>{props.result.suggestions.length} Entries</Typography>
            <Table>
                <TableBody>
                    {props.result.suggestions.map(item => (
                        <React.Fragment key={item.suggestion}>
                            <TableRow>
                                <TableCell>{item.suggestion} (Score: {item.score}, Count: {item.count})</TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            {props.result.top ? (
                <React.Fragment>
                    <Typography variant="h6" style={{paddingLeft: '15px', marginTop: '15px'}}>Top Banner</Typography>
                    <Table>
                        <TableBody>
                            {props.result.top.map(top => (
                                <TableRow>
                                    <TableCell>{top.name} <a href={top.url}>Link</a> ({top.type})</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </React.Fragment>
            ) : null}
        </TableContainer>
    );
};

export default SuggestTermsList;
