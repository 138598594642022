import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import DisplayApiQuery from "./DisplayApiQuery";
import {makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ContentList from "../components/ContentList";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const ContentSearchPage = () => {

    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const [filter, setFilter] = useState({});
    const [usedAggregations, setUsedAggregations] = useState(null);

    const toggleFilterValue = (domain, type, value) => {
        setFilter((previousState) => {
            const newState = {...previousState};
            if (type === null) {
                delete newState[domain];
                return newState;
            }
            newState[domain] = newState[domain] ? {...newState[domain]} : {};
            if (type === undefined) {
                return newState;
            }
            newState[domain][type] = newState[domain][type] ? [...newState[domain][type]] : [];
            const index = newState[domain][type].indexOf(value);
            if (index > -1) {
                newState[domain][type].splice(index, 1);
            } else {
                newState[domain][type].push(value);
            }

            return newState;
        });
    };

    const setMediaPrice = (domain, from, to) => {
        setFilter((previousState) => {
            const newState = {...previousState};
            newState[domain] = {...newState[domain]};
            newState[domain].mediaPrice = {
                from,
                to,
            };

            return newState;
        });
    };

    const setPostTime = (domain, from, to) => {
        setFilter((previousState) => {
            const newState = {...previousState};
            newState[domain] = {...newState[domain]};
            newState[domain].postTime = {
                from,
                to,
            };

            return newState;
        });
    };

    const setRechtsprechungDate = (domain, from, to) => {
        setFilter((previousState) => {
            const newState = {...previousState};
            newState[domain] = {...newState[domain]};
            newState[domain].rechtsprechungDate = {
                from,
                to,
            };

            return newState;
        });
    };

    const toggleAggregation = (aggregation) => {
        setUsedAggregations((previousState) => {
            let newState = previousState ? [...previousState] : [];
            if (newState.indexOf(aggregation) !== -1) {
                newState.splice(newState.indexOf(aggregation), 1);
                if (newState.length === 0) {
                    newState = null;
                }
            } else {
                newState.push(aggregation);
            }

            return newState;
        });
    };

    const searchBody = {
        "from": from,
        "size": size,
        "filters": {},
        "source": {
            "exclude": ['textContent']
        },
        position: 'IFB Demo ESONO'
    };

    if (searchTerm !== '') {
        searchBody["q"] = searchTerm;
    }

    Object.entries(filter).forEach((filtersByDomain) => {
        const domainName = filtersByDomain[0];
        const filters = filtersByDomain[1];

        if (!searchBody.filters[domainName]) {
            searchBody.filters[domainName] = {};
        }

        Object.entries(filters).forEach((values) => {
            if (Array.isArray(values[1]) && values[1].length > 0) {
                searchBody.filters[domainName][values[0]] = values[1];
            }
        });

        if (filters.mediaPrice && (filters.mediaPrice.from || filters.mediaPrice.to)) {
            if (filters.mediaPrice.from) {
                searchBody.filters[domainName].mediaPrice = {from: filters.mediaPrice.from};
            }
            if (filters.mediaPrice.to) {
                searchBody.filters[domainName].mediaPrice = searchBody.filters[domainName].mediaPrice || {};
                searchBody.filters[domainName].mediaPrice.to = filters.mediaPrice.to;
            }
        }

        if (filters.postTime && (filters.postTime.from || filters.postTime.to)) {
            if (filters.postTime.from) {
                searchBody.filters[domainName].postTime = {from: filters.postTime.from};
            }
            if (filters.postTime.to) {
                searchBody.filters[domainName].postTime = searchBody.filters[domainName].postTime || {};
                searchBody.filters[domainName].postTime.to = filters.postTime.to;
            }
        }

        if (filters.rechtsprechungDate && (filters.rechtsprechungDate.from || filters.rechtsprechungDate.to)) {
            if (filters.rechtsprechungDate.from) {
                searchBody.filters[domainName].rechtsprechungDate = {from: filters.rechtsprechungDate.from};
            }
            if (filters.rechtsprechungDate.to) {
                searchBody.filters[domainName].rechtsprechungDate = searchBody.filters[domainName].rechtsprechungDate || {};
                searchBody.filters[domainName].rechtsprechungDate.to = filters.rechtsprechungDate.to;
            }
        }
    });

    if (usedAggregations) {
        searchBody.aggregations = usedAggregations;
    }

    const queryUrl = '/contentSearch';

    const classes = useStyles();

    const componentProps = {
        filter: filter,
        usedAggregations,
        toggleFilterValue,
        setMediaPrice,
        setPostTime,
        setRechtsprechungDate,
        toggleAggregation,
    };

    return (
        <React.Fragment>
            <Typography variant="h1">Content search</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Size" value={size} onChange={(e) => {
                    setSize(e.target.value);
                }} />
                <TextField label="From" value={from} onChange={(e) => {
                    setFrom(e.target.value);
                }} />

            </div>
            <div className={classes.chipRoot}>
                <TextField label="Search term" value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value);
                }} />
            </div>
            <DisplayApiQuery queryUrl={queryUrl} queryBody={searchBody} component={ContentList} componentProps={componentProps}/>
        </React.Fragment>
    );
};

export default ContentSearchPage;
