import React, {useEffect, useState} from 'react';
import ajax from "../ajax/ajax";
import {LinearProgress} from "@material-ui/core";
import PaperWithHeadline from "../components/PaperWithHeadline";
import Snackbar from "@material-ui/core/Snackbar";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import {connect} from 'react-redux';

const DisplayApiQuery = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rawResult, setRawResult] = useState(null);
    const [rawResultShown, setRawResultShown] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const {cancel} = ajax.post(props.baseUrl + props.queryUrl, props.queryBody,(result) => {
            if (undefined !== result.error) {
                setError(result.error);
            } else {
                setError(null);
            }
            setRawResult(result);
            setIsLoading(false);
        }, (errorText, status, xhr) => {
            setIsLoading(false);
            let shownErrorMessage = xhr.responseText;
            if (null === shownErrorMessage || '' === shownErrorMessage) {
                shownErrorMessage = 'Empty response, check network tab or console.';
            }
            setError(shownErrorMessage);
        });

        return () => {
            cancel();
        };
    }, [props.queryUrl, props.queryBody, props.baseUrl]);

    if (true === isLoading) {
        return (<LinearProgress/> );
    }

    const headline = `POST ${props.queryUrl}`;

    return (
        <div style={{marginBottom: '20px'}}>
            {null !== error ? <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open
                message={<span>{error}</span>}
            />: null}
            <PaperWithHeadline headline={headline}>
                {JSON.stringify(props.queryBody, null, 2)}
            </PaperWithHeadline>
            {null !== rawResult ? React.createElement(props.component, {...props.componentProps, result: rawResult}) : null}
            <Button variant="contained" onClick={() => {setRawResultShown(!rawResultShown)}}>Raw result</Button>
            <Collapse in={rawResultShown} unmountOnExit>
                <PaperWithHeadline headline="Raw result">
                    {JSON.stringify(rawResult, null, 2)}
                </PaperWithHeadline>
            </Collapse>
        </div>
    );

};

const stateToProps = (state) => {
  return {
      baseUrl: state.elastic.endpointUrl
  }
};

export default connect(stateToProps)(DisplayApiQuery);
