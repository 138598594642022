import React from 'react';
import {connect} from 'react-redux';

import TextField from "@material-ui/core/TextField";

const Home = (props) => {
    return (
        <React.Fragment>
            <p>
                Aus der Navigation den Bereich auswählen um Queries anzuzeigen.
            </p>

            <TextField fullWidth label="Elastic Endpunkt" value={props.elasticEndpoint} onChange={(e) => {
                props.setElasticEndpoint(e.target.value);
            }} /><br/><br/>

            <TextField fullWidth label="Index Name Themen" value={props.indexNameSeminars} onChange={(e) => {
                props.setIndexNameSeminars(e.target.value);
            }} /><br/><br/>

            <TextField fullWidth label="Index Name Content" value={props.indexNameContent} onChange={(e) => {
                props.setIndexNameContent(e.target.value);
            }} /><br/><br/>

            <TextField fullWidth label="Index Name Keywords" value={props.indexNameKeywords} onChange={(e) => {
                props.setIndexNameKeywords(e.target.value);
            }} />

        </React.Fragment>
    );
};

const stateToProps = (state) => {
    return {
        elasticEndpoint: state.elastic.endpointUrl,
        indexNameSeminars: state.elastic.indexNameSeminars,
        indexNameContent: state.elastic.indexNameContent,
        indexNameKeywords: state.elastic.indexNameKeywords,
    }
};

const actionsToProps = (dispatch) => {
    return {
        setElasticEndpoint: (endpointUrl) => {
            dispatch({
                type: 'applyElasticEndpoint',
                payload: endpointUrl
            });
        },
        setIndexNameSeminars: (name) => {
            dispatch({
                type: 'applyIndexNameSeminars',
                payload: name
            });
        },
        setIndexNameContent: (name) => {
            dispatch({
                type: 'applyIndexNameContent',
                payload: name
            });
        },
        setIndexNameKeywords: (name) => {
            dispatch({
                type: 'applyIndexNameKeywords',
                payload: name
            });
        }
    }
};

export default connect(stateToProps, actionsToProps)(Home);
