import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import DisplayApiQuery from "./DisplayApiQuery";
import {makeStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SuggestTermsList from "../components/SuggestTermsList";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const SuggestSearch = () => {

    const [size, setSize] = useState(10);

    const [searchTerm, setSearchTerm] = useState('');

    const searchBody = {
        "size": size,
        position: 'IFB Demo ESONO'
    };

    if (searchTerm !== '') {
        searchBody["q"] = searchTerm;
    }


    const queryUrl = '/suggestSearch';

    const classes = useStyles();

    const componentProps = {
    };

    return (
        <React.Fragment>
            <Typography variant="h1">Suggest search terms</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Size" value={size} onChange={(e) => {
                    setSize(e.target.value);
                }} />
            </div>
            <div className={classes.chipRoot}>
                <TextField label="Search term" value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value);
                }} />
            </div>
            <DisplayApiQuery queryUrl={queryUrl} queryBody={searchBody} component={SuggestTermsList} componentProps={componentProps}/>
        </React.Fragment>
    );
};

export default SuggestSearch;
