import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import DisplayApiQuery from "./DisplayApiQuery";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core";
import SeminarList from "../components/SeminarList";

const useStyles = makeStyles(theme => ({
    chipRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
    },
}));

const Seminars = (props) => {
    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);
    const [webcode, setWebcode] = useState('');
    const [seminarNumber, setSeminarNumber] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [queryString, setQueryString] = useState('');

    const searchBody = {
        "from": from,
        "size": size,
        "q": '',
        filters: {}
    };

    if (webcode !== '') {
        searchBody.themeId = webcode;
    }

    if (seminarNumber !== '') {
        searchBody.seminarNumber = seminarNumber;
    }

    if (startDate !== '') {
        searchBody.filters.startDate = startDate;
    }

    if (endDate !== '') {
        searchBody.filters.endDate = endDate;
    }

    if (queryString !== '') {
        searchBody.q = queryString;
    }

    const queryUrlSeminars = '/seminars';

    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h1">Seminar search</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Theme ID" value={webcode} onChange={(e) => {
                    setWebcode(e.target.value);
                }} />
                <TextField label="Query string" value={queryString} onChange={(e) => {
                    setQueryString(e.target.value);
                }} />
                <TextField label="Seminar number" value={seminarNumber} onChange={(e) => {
                    setSeminarNumber(e.target.value);
                }} />
                <TextField label="Size" value={size} onChange={(e) => {
                    setSize(e.target.value);
                }} />
                <TextField label="From" value={from} onChange={(e) => {
                    setFrom(e.target.value);
                }} />
            </div>
            <Typography variant="h6" style={{fontSize: '1rem'}}>Dates (Format: 2020-01-01T00:00:00.000)</Typography>
            <div className={classes.chipRoot}>
                <TextField label="Start date" value={startDate} onChange={(e) => {
                    setStartDate(e.target.value);
                }} />
                <TextField label="End date" value={endDate} onChange={(e) => {
                    setEndDate(e.target.value);
                }} />
            </div>
            <DisplayApiQuery queryUrl={queryUrlSeminars} queryBody={searchBody} component={SeminarList}/>
        </React.Fragment>
    );
};

const stateToProps = (state) => {
    return {
        indexNameSeminars: state.elastic.indexNameSeminars,
        indexNameContent: state.elastic.indexNameContent,
        indexNameKeywords: state.elastic.indexNameKeywords,
    }
};

export default connect(stateToProps)(Seminars);
